import { CirclePicker } from "react-color";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export function ColorPicker() {
  const [isOpened, setIsOpened] = useState(false);
  const penStrokeColor = useSelector((state) => state.penStrokeColor);
  const dispatch = useDispatch();
  const selectedElement = useSelector((state) => state.selectedElement);
  const textColor = useSelector((state) => state.textColor);

  const onColorChange = (color, event) => {
    let stateVarToUpdate;
    switch (selectedElement) {
      case "Line":
      case "FreeHandDrawing":
        stateVarToUpdate = "setStrokeColor";
        break;
      case "Textarea":
        stateVarToUpdate = "setTextColor";
        break;
      default:
        break;
    }
    if (!stateVarToUpdate) return;
    dispatch({
      type: stateVarToUpdate,
      payload: color.hex,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
      }}
    >
      <button
        style={{
          width: "30px",
          height: "30px",
          backgroundColor:
            selectedElement === "Textarea" ? textColor : penStrokeColor,
          borderRadius: "50%",
          border: "1px solid #fff",
        }}
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      ></button>
      {isOpened && (
        <div
          style={{
            position: "absolute",
            right: "110%",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <CirclePicker width="210px" onChange={onColorChange} />
        </div>
      )}
    </div>
  );
}
