import { FontSizePicker } from "./FontSizePicker";
import { StrokeWidthPicker } from "./StrokeWidthPicker";
import { makeStyles } from "@material-ui/core/styles";
import { ColorPicker } from "./ColorPicker";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  styleBar: {
    display: "flex",
    "flex-direction": "column",
    gap: "1em",
    padding: "1em",
  },
}));

export function ItemStylingBar(props) {
  const classes = useStyles();
  const selectedElement = useSelector((state) => state.selectedElement);

  return (
    <div className={classes.styleBar}>
      {selectedElement === "Textarea" && <FontSizePicker />}
      {(selectedElement === "FreeHandDrawing" ||
        selectedElement === "Line") && <StrokeWidthPicker />}
      {(selectedElement === "FreeHandDrawing" ||
        selectedElement === "Line" ||
        selectedElement === "Textarea") && <ColorPicker />}
    </div>
  );
}
