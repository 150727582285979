import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

export function FontSizePicker(props) {
  const [isOpened, setIsOpened] = useState(false);
  const textFontSize = useSelector((state) => state.textFontSize);
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <button
        style={{
          width: "30px",
          height: "30px",
          backgroundColor: "#fff",
          border: "1px solid #fff",
        }}
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      >
        {textFontSize}
      </button>
      {isOpened && (
        <div
          style={{
            position: "absolute",
            right: "110%",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <input
            type="range"
            min="8"
            max="40"
            value={textFontSize}
            onChange={(e) => {
              dispatch({
                type: "setTextFontSize",
                payload: Number(e.target.value),
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
