import React, { useState, useRef, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommentPin from "./CommentPin.js";
import CommentTextArea from "./CommentTextArea.js";
import Line from "./Line.js";
import Modal from "./components/Modal/Modal.js";
import FreeHandDrawing from "./components/FreeHandDrawing/FreeHandDrawing.js";
import html2canvas from "html2canvas";
import { DeviceContext } from "./DeviceContext";
import DeleteIcon from "./components/DeleteIcon/DeleteIcon.js";
import { ItemStylingBar } from "./item-styling-bar/ItemStylingBar";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "./components/FileUpload";

const useStyles = makeStyles((theme) => ({
  draftWrapper: {
    width: "90vw",
    "--heightUnderTopNav": "calc(100vh - 64px)",
    height: "calc(var(--heightUnderTopNav) * 0.9)",
    background: "green",
    "--unusedHeightBelowTopNav": "calc(var(--heightUnderTopNav) * 0.1)",
    "margin-left": "calc(10vw / 2)",
    display: "flex",
    "flex-direction": "column",
    "--containerHeaderHeight": "32px",
  },
  feedbackContainerHeader: {
    height: "var(--containerHeaderHeight)",
    display: "flex",
    justifyContent: "center",
  },
  draftBody: {
    display: "grid",
    "grid-template-columns": ".5fr 5fr .5fr",
    width: "100%",
    height: "calc(100% - var(--containerHeaderHeight))",
  },
  flexContainer: {
    display: "flex",
  },
  cyan: {
    background: "cyan",
  },
  magenta: {
    background: "magenta",
  },
  restrictDimensions: {
    "max-height": "2000px",
    "max-width": "2000px",
  },
  displayFlex: {
    display: "flex",
  },
  alternativeToTouchScroll: {
    overflow: "auto",
    touchAction: "none",
    scrollbarWidth: "auto", //  Width of the scrollbar (for Firefox)
    scrollbarColor: "#000 #F5F5F5", //Color of the scrollbar (for Firefox)
    "&::-webkit-scrollbar": {
      width: "20px", // Width of the scrollbar
      height: "20px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#F5F5F5",
      "-webkit-box-shadow": "inset 0px 0px 13px rgba(0,0,0,1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(220, 220, 220, 1)",
    },
  },
  overflowAuto: {
    overflow: "auto",
  },
  centerAlign: {
    margin: "auto",
  },
  imageContainer: {
    position: "relative",
    "& .overlay": {
      position: "absolute",
      top: "0",
      left: "0",
    },
  },
  displayBlock: {
    display: "block",
  },
  containerElBtns: {
    display: "grid",
    marginTop: "15px",
  },
  elBtns: {
    backgroundColor: "#359139",
    border: "none",
    color: "white",
    padding: "5px",
    textAlign: "center",
    textDecoration: "none",
    fontSize: "13px",
    margin: "4px 2px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  selected: {
    border: "2px solid black",
  },
}));

export default function Feedback(argument) {
  const dispatch = useDispatch();
  const textFontSize = useSelector((state) => state.textFontSize);
  const textColor = useSelector((state) => state.textColor);
  const penStrokeColor = useSelector((state) => state.penStrokeColor);
  const penStrokeWidth = useSelector((state) => state.penStrokeWidth);
  const deviceContext = useContext(DeviceContext);
  const classes = useStyles();
  const [imagePath, setImagePath] = useState(null);
  const [commentElements, setCommentElements] = useState([]);
  const [elementID, setElementID] = useState(0);
  const [number, setNumber] = useState(0);
  const [selectedPointer, setSelectedPointer] = useState("Textarea");

  useEffect(() => {
    dispatch({
      type: "setSelectedElement",
      payload: selectedPointer,
    });
  }, [selectedPointer, dispatch]);

  const imageParentRef = useRef(null);
  const imageScrollOwnerRef = useRef(null);
  const lineRef = useRef();
  const freeHandDrawingRef = useRef();
  const textAreaRef = useRef();
  const [lineDragging, setLineDragging] = useState({
    status: false,
    lineId: "",
    diffInInitialLeft: 0,
    diffInInitialTop: 0,
    diffInFinalLeft: 0,
    diffInFinalTop: 0,
  });
  const [textArea, settextArea] = useState({
    status: "New",
    textAreaId: "",
    diffInLeft: 0,
    diffInTop: 0,
    value: "",
  });
  const [isDrawing, setIsDrawing] = useState(false);
  const [isFreeHandShifting, setIsFreeHandShifting] = useState(false);
  const [freeHandDraggingInitial, setFreeHandDraggingInitial] = useState({
    startX: 0,
    startY: 0,
    drawingPath: "",
    elementID: 0,
  });
  const [currentElement, setCurrentElement] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    imageUrl: "",
  });

  const onDiscard = () => {
    setModalInfo({ ...modalInfo, show: !modalInfo.show });
  };

  const onFileChange = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      if (imagePath === null) {
        setImagePath(imageUrl);
      } else {
        setModalInfo({ ...modalInfo, show: true, imageUrl: imageUrl });
      }
    }
  };

  const onProceed = () => {
    setCommentElements([]);
    setImagePath(modalInfo.imageUrl);
    setModalInfo({ show: false, imageUrl: "" });
  };

  const pasteImage = async (e) => {
    e.preventDefault();
    const clipboardItems =
      typeof navigator?.clipboard?.read === "function"
        ? await navigator.clipboard.read()
        : e.clipboardData.files;

    for (const clipboardItem of clipboardItems) {
      let blob;
      if (clipboardItem.type?.startsWith("image/")) {
        // for browsers that do not support navigator.clipboard.read() api.
        blob = clipboardItem;
        let imageUrl = URL.createObjectURL(blob);
        if (imagePath === null) {
          setImagePath(imageUrl);
        } else {
          setModalInfo({ ...modalInfo, show: true, imageUrl: imageUrl });
        }
      } else {
        const imageTypes = clipboardItem.types?.filter((type) =>
          type.startsWith("image/")
        );
        for (const imageType of imageTypes) {
          blob = await clipboardItem.getType(imageType);
          let imageUrl = URL.createObjectURL(blob);
          if (imagePath === null) {
            setImagePath(imageUrl);
          } else {
            setModalInfo({ ...modalInfo, show: true, imageUrl: imageUrl });
          }
        }
      }
    }
  };

  const manageSelection = (pointerID) => {
    if (pointerID) {
      setCommentElements(
        commentElements.map((el) =>
          el.id === pointerID
            ? { ...el, selected: true }
            : { ...el, selected: false }
        )
      );
    } else {
      setCommentElements(
        commentElements.map((el) => {
          return { ...el, selected: false };
        })
      );
    }
  };
  const onLineDragStart = (
    elementID,
    diffInStartX,
    diffInStartY,
    diffInEndX,
    diffInEndY
  ) => {
    setLineDragging({
      ...lineDragging,
      status: true,
      lineId: elementID,
      diffInInitialLeft: diffInStartX,
      diffInInitialTop: diffInStartY,
      diffInFinalLeft: diffInEndX,
      diffInFinalTop: diffInEndY,
    });
    setSelectedPointer("Line");
  };

  const onTextAreaDragStart = (elementID, diffInLeft, diffInTop) => {
    settextArea({
      ...textArea,
      status: "Drag",
      textAreaId: elementID,
      diffInLeft,
      diffInTop,
    });
    setSelectedPointer("Textarea");
  };

  const textAreaStatus = (status) => {
    settextArea({
      ...textArea,
      status: status,
    });
  };

  const handleDown = (event) => {
    event.stopPropagation();
    if (event.type === "mousedown" || event.type === "touchstart") {
      let { clientX, clientY } =
        event.type === "touchstart" ? event.touches[0] : event;
      if (selectedPointer === "Line") {
        setIsDrawing(true);
        manageSelection();
        let line = {
          startX:
            clientX +
            imageScrollOwnerRef.current.scrollLeft -
            imageParentRef.current.offsetLeft,
          startY:
            clientY +
            imageScrollOwnerRef.current.scrollTop -
            imageParentRef.current.offsetTop,
        };
        line.endX = line.startX;
        line.endY = line.startY;
        const newElement = {
          initialLeftOffset: line.startX,
          initialTopOffset: line.startY,
          finalLeftOffset: line.endX,
          finalTopOffset: line.endY,
          id: `el-${elementID.toString()}`,
          type: selectedPointer,
          selected: true,
        };
        setCommentElements((oldArray) => [...oldArray, newElement]);
        setCurrentElement(newElement.id);
      }
      if (selectedPointer === "FreeHandDrawing") {
        setIsDrawing(true);
        manageSelection();
        clientX =
          clientX +
          imageScrollOwnerRef.current.scrollLeft -
          imageParentRef.current.offsetLeft;
        clientY =
          clientY +
          imageScrollOwnerRef.current.scrollTop -
          imageParentRef.current.offsetTop;
        let drawingPath = `M ${clientX} ${clientY} L ${clientX} ${clientY}`;
        const newElement = {
          drawingPath: drawingPath,
          id: `el-${elementID.toString()}`,
          type: selectedPointer,
          selected: true,
        };
        setCommentElements((oldArray) => [...oldArray, newElement]);
        setCurrentElement(newElement.id);
      }
    }
  };

  const handleUp = (event) => {
    event.stopPropagation();
    if (event.type === "mouseup" || event.type === "touchend") {
      let { clientX, clientY } =
        event.type === "touchend" ? event.changedTouches[0] : event;
      if (selectedPointer === "Line") {
        if (!lineDragging.status) {
          // If lineDragging state is not true the new line will be added.
          const currentLine = commentElements.find(
            (item) => item.id === currentElement
          );
          let line = {
            ...currentLine,
            finalLeftOffset:
              clientX +
              imageScrollOwnerRef.current.scrollLeft -
              imageParentRef.current.offsetLeft,
            finalTopOffset:
              clientY +
              imageScrollOwnerRef.current.scrollTop -
              imageParentRef.current.offsetTop,
          };
          let newCommentElements = commentElements.map((item) => {
            if (item.id === currentElement) {
              return line;
            } else {
              return item;
            }
          });
          setCommentElements(newCommentElements);
          setIsDrawing(false);
          setCurrentElement(null);
          // increment element key to be used for identifying next element uniquely for the rendering loop
          setElementID(elementID + 1);
        } else {
          const updatedPositions = lineRef.current.calculateUpdatedPositions(
            event,
            lineDragging.lineId,
            lineDragging.diffInInitialLeft,
            lineDragging.diffInInitialTop,
            lineDragging.diffInFinalLeft,
            lineDragging.diffInFinalTop
          );
          const result = commentElements.map((el) =>
            el.id === updatedPositions.elId
              ? {
                  ...el,
                  initialLeftOffset: updatedPositions.updatedInitialLeft,
                  initialTopOffset: updatedPositions.updatedInitialTop,
                  finalLeftOffset: updatedPositions.updatedFinalLeft,
                  finalTopOffset: updatedPositions.updatedFinalTop,
                }
              : { ...el }
          );
          setCommentElements(result);
          lineDragging.status = false;
        }
      }
      if (selectedPointer === "Pin") {
        // Making all the previous pins not selected. The new one will be selected by default
        manageSelection();
        // pin left offset = x-offset-of-click-from-viewport-left + image-horizontal-scroll-offset-from-left - left-offset-of-image-horizontal-scroll-from-its-start
        const newElementLeftOffset =
          clientX +
          imageScrollOwnerRef.current.scrollLeft -
          imageParentRef.current.offsetLeft;
        // pin top offset = y-offset-of-click-from-viewport-top + vertical-scroll-offset-of-image - top-offset-of-image-vertical-scroll-from-its-start
        const newElementTopOffset =
          clientY +
          imageScrollOwnerRef.current.scrollTop -
          imageParentRef.current.offsetTop;
        const newElement = {
          leftOffset: newElementLeftOffset,
          topOffset: newElementTopOffset,
          id: `el-${elementID.toString()}`,
          number: number + 1,
          selected: true,
          type: selectedPointer,
        };
        setCommentElements((oldArray) => [...oldArray, newElement]);
        // increment element key to be used for identifying next element uniquely for the rendering loop
        setElementID(elementID + 1);
        setNumber(selectedPointer === "Pin" ? number + 1 : number + 0);
      }
      if (selectedPointer === "Textarea" && textArea.status === "New") {
        manageSelection();
        // pin left offset = x-offset-of-click-from-viewport-left + image-horizontal-scroll-offset-from-left - left-offset-of-image-horizontal-scroll-from-its-start
        const newElementLeftOffset =
          clientX +
          imageScrollOwnerRef.current.scrollLeft -
          imageParentRef.current.offsetLeft;
        // pin top offset = y-offset-of-click-from-viewport-top + vertical-scroll-offset-of-image - top-offset-of-image-vertical-scroll-from-its-start
        const newElementTopOffset =
          clientY +
          imageScrollOwnerRef.current.scrollTop -
          imageParentRef.current.offsetTop;
        const newElement = {
          leftOffset: newElementLeftOffset,
          topOffset: newElementTopOffset,
          id: `el-${elementID.toString()}`,
          number: number + 1,
          selected: true,
          type: selectedPointer,
        };
        setCommentElements((oldArray) => [...oldArray, newElement]);
        // increment element key to be used for identifying next element uniquely for the rendering loop
        setElementID(elementID + 1);
      }
      if (selectedPointer === "Textarea" && textArea.status === "Drag") {
        const updatedPositions = textAreaRef.current.calculateUpdatedPositions(
          event,
          textArea.textAreaId,
          textArea.diffInLeft,
          textArea.diffInTop
        );
        const result = commentElements.map((el) =>
          el.id === updatedPositions.elId
            ? {
                ...el,
                leftOffset: updatedPositions.updatedLeft,
                topOffset: updatedPositions.updatedTop,
              }
            : { ...el }
        );
        setCommentElements(result);
        textArea.status = "New";
      }
      if (selectedPointer === "Textarea" && textArea.status === "Resize") {
        textArea.status = "New";
        return;
      }
      if (selectedPointer === "FreeHandDrawing" && !isFreeHandShifting) {
        clientX =
          clientX +
          imageScrollOwnerRef.current.scrollLeft -
          imageParentRef.current.offsetLeft;
        clientY =
          clientY +
          imageScrollOwnerRef.current.scrollTop -
          imageParentRef.current.offsetTop;
        setCommentElements((elements) => {
          return elements.map((item) => {
            if (item.id === currentElement) {
              // Check if drawingPath already exists
              const existingPath = item.drawingPath || "";

              // Append the new path data to the existing path
              const updatedPath = `${existingPath} L ${clientX} ${clientY}`;

              let FreeHandDrawing = {
                ...item,
                drawingPath: updatedPath,
              };
              return FreeHandDrawing;
            } else {
              return item;
            }
          });
        });
        setIsDrawing(false);
        setCurrentElement(null);
        // increment element key to be used for identifying next element uniquely for the rendering loop
        setElementID(elementID + 1);
      }
      if (selectedPointer === "FreeHandDrawing" && isFreeHandShifting) {
        toggleFreeHandShifting(false);
      }
    }
  };

  const handleMove = (event) => {
    if (isDrawing) {
      let { clientX, clientY } =
        event.type === "touchmove" ? event.touches[0] : event;
      clientX =
        clientX +
        imageScrollOwnerRef.current.scrollLeft -
        imageParentRef.current.offsetLeft;
      clientY =
        clientY +
        imageScrollOwnerRef.current.scrollTop -
        imageParentRef.current.offsetTop;
      if (selectedPointer === "FreeHandDrawing" && !isFreeHandShifting) {
        setCommentElements((elements) => {
          return elements.map((item) => {
            if (item.id === currentElement) {
              // Check if drawingPath already exists
              const existingPath = item.drawingPath || "";

              // Append the new path data to the existing path
              const updatedPath = `${existingPath} L ${clientX} ${clientY}`;

              let FreeHandDrawing = {
                ...item,
                drawingPath: updatedPath,
              };
              return FreeHandDrawing;
            } else {
              return item;
            }
          });
        });
      } else if (selectedPointer === "Line" && currentElement) {
        setCommentElements((elements) => {
          return elements.map((item) => {
            if (item.id === currentElement) {
              let line = {
                ...item,
                finalLeftOffset: clientX,
                finalTopOffset: clientY,
              };
              return line;
            } else {
              return item;
            }
          });
        });
      }
    } else if (lineDragging.status) {
      const updatedPositions = lineRef.current.calculateUpdatedPositions(
        event,
        lineDragging.lineId,
        lineDragging.diffInInitialLeft,
        lineDragging.diffInInitialTop,
        lineDragging.diffInFinalLeft,
        lineDragging.diffInFinalTop
      );
      const result = commentElements.map((el) =>
        el.id === updatedPositions.elId
          ? {
              ...el,
              initialLeftOffset: updatedPositions.updatedInitialLeft,
              initialTopOffset: updatedPositions.updatedInitialTop,
              finalLeftOffset: updatedPositions.updatedFinalLeft,
              finalTopOffset: updatedPositions.updatedFinalTop,
            }
          : { ...el }
      );
      setCommentElements(result);
    } else if (selectedPointer === "FreeHandDrawing" && isFreeHandShifting) {
      const updatedPositions =
        freeHandDrawingRef.current.calculateUpdatedPositions(
          event,
          freeHandDraggingInitial
        );
      const result = commentElements.map((el) =>
        el.id === updatedPositions.elId
          ? {
              ...el,
              drawingPath: updatedPositions.drawingPath,
            }
          : { ...el }
      );
      setCommentElements(result);
    } else if (selectedPointer === "Textarea" && textArea.status === "Drag") {
      const updatedPositions = textAreaRef.current.calculateUpdatedPositions(
        event,
        textArea.textAreaId,
        textArea.diffInLeft,
        textArea.diffInTop
      );
      const result = commentElements.map((el) =>
        el.id === updatedPositions.elId
          ? {
              ...el,
              leftOffset: updatedPositions.updatedLeft,
              topOffset: updatedPositions.updatedTop,
            }
          : { ...el }
      );
      setCommentElements(result);
    } else {
      setIsDrawing(false);
    }
  };

  const handleOuterMouseUp = () => {
    setIsDrawing(false);
    if (currentElement) {
      setCommentElements((elements) =>
        elements.filter((item) => item.id !== currentElement)
      );
      setCurrentElement(null);
    }
    if (lineDragging.status) {
      lineDragging.status = false;
    }
    if (textArea.status === "Drag") {
      textArea.status = "New";
    }
  };
  const deleteElement = (elementID) => {
    let originalElements = commentElements.filter((el) => {
      return el.id !== elementID;
    });
    setCommentElements(originalElements);
  };
  const toggleFreeHandShifting = (status) => {
    setIsFreeHandShifting(status);
  };
  const handleFreeHandDragging = (elementID, preShiftingDrawingPath, event) => {
    setSelectedPointer("FreeHandDrawing");
    setFreeHandDraggingInitial({
      startX: event.clientX,
      startY: event.clientY,
      drawingPath: preShiftingDrawingPath,
      elementID: elementID,
    });
  };
  const handlePreview = () => {
    setSelectedPointer("Preview");

    html2canvas(imageParentRef.current, { scale: 1 }).then((canvas) => {
      const convertedImage = canvas.toDataURL("image/png");

      // Create a new browser window
      const newWindow = window.open("", "_blank");
      if (newWindow) {
        // Generate the HTML content for the new tab with the captured image
        const previewContent = `
        <html>
          <head>
            <title>Image Preview</title>
          </head>
          <style>
          .download-btn {
            background:#359139;
            color:white;
            padding:7px;
            border:2px solid #359139;
            border-radius:5px;
            margin-bottom: 7px;
            cursor: pointer;
          }
          </style>
          <script>
            const downloadImage = () => {
              const imageToDownload = document.getElementById("downloadImg");
              const anchor = document.createElement('a');
              anchor.setAttribute("href", imageToDownload.src);
              anchor.setAttribute("download", "finalImage.png");
              anchor.click();
              anchor.remove();
            }
          </script>
          <body>
              <div>
                <button class="download-btn" onclick="downloadImage()">Download Image</button>
              </div>
              <div>
                <img src="${convertedImage}" alt="Captured Image" id="downloadImg"/>
              </div>
          </body>
        </html>
      `;

        newWindow.document.open();
        newWindow.document.write(previewContent);
        newWindow.document.close();
      }
    });
  };

  const onTextAreaChange = (event, id) => {
    const result = commentElements.map((el) =>
      el.id === id ? { ...el, value: event.target.value } : { ...el }
    );
    setCommentElements(result);
  };

  return (
    <>
      {modalInfo.show && (
        <Modal
          onDiscard={onDiscard}
          onProceed={onProceed}
          headerText={"Do you want to proceed with the following image?"}
        >
          <img src={modalInfo.imageUrl} alt="Resource not found" />
        </Modal>
      )}
      <div
        className={classes.draftWrapper}
        onPaste={pasteImage}
        onMouseUp={handleOuterMouseUp}
      >
        <div className={classes.feedbackContainerHeader}>
          <FileUploader
            onFileChange={onFileChange}
            selectedImgPath={imagePath}
          />
        </div>
        <div className={classes.draftBody}>
          <div className={classes.magenta}>
            <div className={classes.containerElBtns}>
              <button
                className={`${classes.elBtns}  ${
                  selectedPointer === "Pin" ? classes.selected : ""
                }`}
                onClick={() => setSelectedPointer("Pin")}
              >
                Pin
              </button>
              <button
                className={`${classes.elBtns}  ${
                  selectedPointer === "Textarea" ? classes.selected : ""
                }`}
                onClick={() => setSelectedPointer("Textarea")}
              >
                Text
              </button>
              <button
                className={`${classes.elBtns}  ${
                  selectedPointer === "Line" ? classes.selected : ""
                }`}
                onClick={() => setSelectedPointer("Line")}
              >
                Line
              </button>
              <button
                className={`${classes.elBtns}  ${
                  selectedPointer === "FreeHandDrawing" ? classes.selected : ""
                }`}
                onClick={() => setSelectedPointer("FreeHandDrawing")}
              >
                Free Hand Drawing
              </button>
              <button
                onClick={handlePreview}
                disabled={imagePath === null}
                className={`${classes.elBtns}  ${
                  selectedPointer === "Preview" ? classes.selected : ""
                }`}
              >
                Preview
              </button>
            </div>
          </div>
          <div
            className={`${classes.cyan} ${classes.displayFlex} ${
              classes.overflowAuto
            }
              ${
                deviceContext.isTouchEnabled && classes.alternativeToTouchScroll
              }`}
            ref={imageScrollOwnerRef}
          >
            {imagePath ? (
              <div
                className={`${classes.imageContainer} ${classes.centerAlign}`}
                onMouseDown={handleDown}
                // To avoid revoking of handleUp function in real touch devices onMouseUp instead of ontouchStart
                onMouseUp={!deviceContext.isTouchEnabled && handleUp}
                onMouseMove={handleMove}
                onTouchStart={handleDown}
                onTouchMove={handleMove}
                onTouchEnd={handleUp}
                ref={imageParentRef}
              >
                <img
                  src={imagePath}
                  alt="could not be loaded.. plz try again!"
                  className={`${classes.restrictDimensions} ${classes.displayBlock}`}
                />
                <svg className="overlay" width="100%" height="100%">
                  {commentElements.map((el) => (
                    <React.Fragment key={el.id}>
                      {el.type === "Pin" && (
                        <CommentPin
                          id={el.id}
                          offsetLeft={el.leftOffset}
                          offsetTop={el.topOffset}
                          number={el.number}
                          selected={el.selected}
                          onSelect={manageSelection}
                        />
                      )}
                      {el.type === "Textarea" && (
                        <CommentTextArea
                          id={el.id}
                          number={el.number}
                          offsetTop={el.topOffset}
                          offsetLeft={el.leftOffset}
                          dragStart={onTextAreaDragStart}
                          selectedPointer={selectedPointer}
                          imgParentRef={imageParentRef}
                          imgScrollRef={imageScrollOwnerRef}
                          ref={textAreaRef}
                          textAreaStatus={textAreaStatus}
                          onTextAreaChange={onTextAreaChange}
                          value={el.value}
                          selected={el.selected}
                          onSelect={manageSelection}
                          textColor={textColor}
                          textFontSize={textFontSize}
                        />
                      )}
                      {el.type === "Line" && (
                        <Line
                          id={el.id}
                          initialLeftOffset={el.initialLeftOffset}
                          initialTopOffset={el.initialTopOffset}
                          finalLeftOffset={el.finalLeftOffset}
                          finalTopOffset={el.finalTopOffset}
                          selected={el.selected}
                          selectedPointer={selectedPointer}
                          dragStart={onLineDragStart}
                          imgParentRef={imageParentRef}
                          imgScrollRef={imageScrollOwnerRef}
                          ref={lineRef}
                          onSelect={manageSelection}
                          strokeWidth={penStrokeWidth}
                          strokeColor={penStrokeColor}
                        />
                      )}
                      {el.type === "FreeHandDrawing" && (
                        <FreeHandDrawing
                          id={el.id}
                          drawingPath={el.drawingPath}
                          selectedPointer={selectedPointer}
                          toggleFreeHandShifting={toggleFreeHandShifting}
                          handleFreeHandDragging={handleFreeHandDragging}
                          ref={freeHandDrawingRef}
                          selected={el.selected}
                          onSelect={manageSelection}
                          strokeWidth={penStrokeWidth}
                          strokeColor={penStrokeColor}
                        />
                      )}
                      {el.selected && (
                        <DeleteIcon el={el} deleteElement={deleteElement} />
                      )}
                    </React.Fragment>
                  ))}
                </svg>
              </div>
            ) : null}
          </div>
          <div className={classes.magenta}>
            <ItemStylingBar />
          </div>
        </div>
      </div>
    </>
  );
}
