import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

export function useStrokeWidthSync({ selected, strokeWidthInState }) {
  const [localStrokeWidth, setLocalStrokeWidth] = useState(strokeWidthInState);

  const prevValues = useRef({
    selected: selected,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (selected) {
      // If the trigger is this element coming under focus/selection, reset strokeWidth picker's
      // value via app state to the elem's strokeWidth val.
      if (selected !== prevValues.current.selected) {
        dispatch({
          type: "setStrokeWidth",
          payload: localStrokeWidth,
        });
      } else {
        // If trigger is NOT this elem coming under focus/selection but a change in app strokeWidth
        // val (represented by strokeWidth prop here), update strokewidth for this element.
        if (strokeWidthInState !== localStrokeWidth) {
          setLocalStrokeWidth(strokeWidthInState);
        }
      }
    }
    prevValues.current = {
      selected: selected,
    };
  }, [selected, strokeWidthInState, localStrokeWidth, dispatch]);
  return localStrokeWidth;
}

export function useStrokeColorSync({ selected, strokeColorInState }) {
  const [localStrokeColor, setLocalStrokeColor] = useState(strokeColorInState);

  const prevValues = useRef({
    selected: selected,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (selected) {
      if (selected !== prevValues.current.selected) {
        dispatch({
          type: "setStrokeColor",
          payload: localStrokeColor,
        });
      } else {
        if (strokeColorInState !== localStrokeColor) {
          setLocalStrokeColor(strokeColorInState);
        }
      }
    }
    prevValues.current = {
      selected: selected,
    };
  }, [selected, strokeColorInState, localStrokeColor, dispatch]);
  return localStrokeColor;
}

export function useTextColorSync({ selected, textColorInState }) {
  const [localTextColor, setLocalTextColor] = useState(textColorInState);

  const prevValues = useRef({
    selected: selected,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (selected) {
      // If the trigger is this element coming under focus/selection, update app state with the
      // elem's attribute val.
      if (selected !== prevValues.current.selected) {
        dispatch({
          type: "setTextColor",
          payload: localTextColor,
        });
      } else {
        // If trigger is NOT this elem coming under focus/selection but a change in the attribute
        // val in state, update the elem with attribute val in app state.
        if (textColorInState !== localTextColor) {
          setLocalTextColor(textColorInState);
        }
      }
    }
    prevValues.current = {
      selected: selected,
    };
  }, [selected, textColorInState, localTextColor, dispatch]);
  return localTextColor;
}

export function useTextSizeSync({ selected, textSizeInState }) {
  const [localTextSize, setLocalTextSize] = useState(textSizeInState);

  const prevValues = useRef({
    selected: selected,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (selected) {
      // If the trigger is this element coming under focus/selection, update app state with the
      // elem's attribute val.
      if (selected !== prevValues.current.selected) {
        console.log(localTextSize);
        dispatch({
          type: "setTextFontSize",
          payload: localTextSize,
        });
      } else {
        // If trigger is NOT this elem coming under focus/selection but a change in the attribute
        // val in state, update the elem with attribute val in app state.
        if (textSizeInState !== localTextSize) {
          setLocalTextSize(textSizeInState);
        }
      }
    }
    prevValues.current = {
      selected: selected,
    };
  }, [selected, textSizeInState, localTextSize, dispatch]);
  return localTextSize;
}
