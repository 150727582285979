export function FileUploader({ onFileChange, selectedImgPath }) {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {/* Hidden File Input */}
      <input
        type="file"
        id="file-upload"
        onChange={onFileChange}
        style={{ display: "none" }} // Hide default input
      />

      {/* Custom Label Button */}
      <label
        htmlFor="file-upload"
        style={{
          margin: "auto 1em",
          padding: "2px 10px",
          background: "RGB(233, 232, 237)",
          borderRadius: "5px",
          cursor: "pointer",
          display: "inline-block",
        }}
      >
        {selectedImgPath ? "Change" : "Select"} Image
      </label>
    </div>
  );
}
