const initialState = {
  textFontSize: 16,
  penStrokeWidth: 3,
  penStrokeColor: "#f00",
  textColor: "#000",
  selectedElement: "",
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setTextFontSize":
      return {
        ...state,
        textFontSize: action.payload,
      };
    case "setSelectedElement":
      return {
        ...state,
        selectedElement: action.payload,
      };
    case "setTextColor":
      return {
        ...state,
        textColor: action.payload,
      };
    case "setStrokeColor":
      return {
        ...state,
        penStrokeColor: action.payload,
      };
    case "setStrokeWidth":
      return {
        ...state,
        penStrokeWidth: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
